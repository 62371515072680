import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {StudentAbsenceCustomClassPageModule} from "./pages/teacher/student-absence-custom-class/student-absence-custom-class.module";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/route/route.module').then( m => m.RoutePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'student-message',
    loadChildren: () => import('./pages/student/student-message/student-message.module').then(m => m.StudentMessagePageModule)
  },
  {
    path: 'student-absence',
    loadChildren: () => import('./pages/student/student-absence/student-absence.module').then( m => m.StudentAbsencePageModule)
  },
  {
    path: 'student-review',
    loadChildren: () => import('./pages/student/student-review/student-review.module').then( m => m.StudentReviewPageModule)
  },
  {
    path: 'student-schedule',
    loadChildren: () => import('./pages/student/student-schedule/student-schedule.module').then( m => m.StudentSchedulePageModule)
  },
  {
    path: 'student-mark',
    loadChildren: () => import('./pages/student/student-mark/student-mark.module').then( m => m.StudentMarkPageModule)
  },
  {
    path: 'student-absence-request',
    loadChildren: () => import('./pages/student/student-absence-request/student-absence-request.module')
      .then( m => m.StudentAbsenceRequestPageModule)
  },
  {
    path: 'student-chat',
    loadChildren: () => import('./pages/student/student-chat/student-chat.module').then( m => m.StudentChatPageModule)
  },
  {
    path: 'student-jit-meeting',
    loadChildren: () => import('./pages/student/student-jit-meeting/student-jit-meeting.module').then( m => m.StudentJitMeetingPageModule)
  },
  {
    path: 'teacher-class-contact',
    loadChildren: () => import('./pages/teacher/teacher-class-contact/teacher-class-contact.module')
      .then( m => m.TeacherClassContactPageModule)
  },
  {
    path: 'teacher-department-contact',
    loadChildren: () => import('./pages/teacher/teacher-department-contact/teacher-department-contact.module')
      .then( m => m.TeacherDepartmentContactPageModule)
  },
  {
    path: 'teacher-send-sms',
    loadChildren: () => import('./pages/teacher/teacher-send-sms/teacher-send-sms.module').then( m => m.TeacherSendSmsPageModule)
  },
  {
    path: 'teacher-send-media',
    loadChildren: () => import('./pages/teacher/teacher-send-media/teacher-send-media.module').then( m => m.TeacherSendMediaPageModule)
  },
  {
    path: 'student-polling',
    loadChildren: () => import('./pages/student/student-polling/student-polling.module').then( m => m.StudentPollingPageModule)
  },
  {
    path: 'student-fee',
    loadChildren: () => import('./pages/student/student-fee/student-fee.module').then( m => m.StudentFeePageModule)
  },
  {
    path: 'teacher-absence',
    loadChildren: () => import('./pages/teacher/teacher-absence/teacher-absence.module').then( m => m.TeacherAbsencePageModule)
  },
  {
    path: 'teacher-review-cap2',
    loadChildren: () => import('./pages/teacher/teacher-review-cap2/teacher-review-cap2.module').then( m => m.TeacherReviewCap2PageModule)
  },
  {
    path: 'teacher-review-cap1',
    loadChildren: () => import('./pages/teacher/teacher-review-cap1/teacher-review-cap1.module').then( m => m.TeacherReviewCap1PageModule)
  },
  {
    path: 'teacher-send-history',
    loadChildren: () => import('./pages/teacher/teacher-send-history/teacher-send-history.module').then( m => m.TeacherSendHistoryPageModule)
  },
  {
    path: 'teacher-polling',
    loadChildren: () => import('./pages/teacher/teacher-polling/teacher-polling.module').then( m => m.TeacherPollingPageModule)
  },
  {
    path: 'article-detail',
    loadChildren: () => import('./pages/general/article-detail/article-detail.module').then( m => m.ArticleDetailPageModule)
  },
  {
    path: 'teacher-absence-approve',
    loadChildren: () => import('./pages/teacher/teacher-absence-approve/teacher-absence-approve.module').then( m => m.TeacherAbsenceApprovePageModule)
  },
  {
    path: 'student-library',
    loadChildren: () => import('./pages/student/student-library/student-library.module').then( m => m.StudentLibraryPageModule)
  },
  {
    path: 'newsfeed',
    loadChildren: () => import('./pages/general/newsfeed/newsfeed.module').then( m => m.NewsfeedPageModule)
  },
  {
    path: 'feed-detail',
    loadChildren: () => import('./pages/general/feeddetail/feeddetail.module').then( m => m.FeeddetailPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/general/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'teacher-polling-mngt',
    loadChildren: () => import('./pages/teacher/teacher-polling-mngt/teacher-polling-mngt.module').then( m => m.TeacherPollingMngtPageModule)
  },
  {
    path: 'teacher-polling-detail',
    loadChildren: () => import('./pages/teacher/teacher-polling-detail/teacher-polling-detail.module').then( m => m.TeacherPollingDetailPageModule)
  },
  {
    path: 'student-polling-detail',
    loadChildren: () => import('./pages/student/student-polling-detail/student-polling-detail.module').then( m => m.StudentPollingDetailPageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./pages/general/image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  },
  {
    path: 'schedule-news',
    loadChildren: () => import('./pages/teacher/schedule-news/schedule-news.module').then( m => m.ScheduleNewsPageModule)
  },
  {
    path: 'staff-attendance',
    loadChildren: () => import('./pages/teacher/staff-attendance/staff-attendance.module').then( m => m.StaffAttendancePageModule)
  },
  {
    path: 'student-extra-activity',
    loadChildren: () => import('./pages/teacher/student-extra-activity/student-extra-activity.module').then( m => m.StudentExtraActivityPageModule)
  },
  {
    path: 'student-absence-class-custom',
    loadChildren: () => import('./pages/teacher/student-absence-custom-class/student-absence-custom-class.module').then( m => m.StudentAbsenceCustomClassPageModule)
  },
  {
    path: 'teacher-homework',
    loadChildren: () => import('./pages/teacher/teacher-homework/teacher-homework.module').then( m => m.TeacherHomeworkPageModule)
  },
  {
    path: 'teacher-homework-detail',
    loadChildren: () => import('./pages/teacher/teacher-homework-detail/teacher-homework-detail.module').then( m => m.TeacherHomeworkDetailPageModule)
  },
  {
    path: 'student-homework',
    loadChildren: () => import('./pages/student/student-homework/student-homework.module').then( m => m.StudentHomeworkPageModule)
  },
  {
    path: 'teacher-room-registration',
    loadChildren: () => import('./pages/teacher/teacher-room-registration/teacher-room-registration.module').then( m => m.TeacherRoomRegistrationPageModule)
  },
  {
    path: 'teacher-observation',
    loadChildren: () => import('./pages/teacher/teacher-observation/teacher-observation.module').then( m => m.TeacherObservationPageModule)
  },
  {
    path: 'student-camera-log',
    loadChildren: () => import('./pages/student/student-camera-log/student-camera-log.module').then( m => m.StudentCameraLogPageModule)
  },
  {
    path: 'student-absence-custom-class',
    loadChildren: () => import('./pages/teacher/student-absence-custom-class/student-absence-custom-class.module').then( m => m.StudentAbsenceCustomClassPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
