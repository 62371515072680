import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage-angular';
import {NgxIonicImageViewerModule} from 'ngx-ionic-image-viewer';
/*import { PhotoViewer } from '@ionic-native/photo-viewer';*/
import { AppVersion } from '@ionic-native/app-version/ngx';
import {DatePipe} from '@angular/common';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from '../environments/environment';
/*https://www.npmjs.com/package/ngx-ionic-image-viewer*/

// @ts-ignore
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({animated: false, swipeBackEnabled: false}),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    NgxIonicImageViewerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
  ],
  providers: [DatePipe, {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, AppVersion],
  bootstrap: [AppComponent],
})
export class AppModule {}
