export class Constant {
  public static readonly DATE_FMT = 'dd/MM/yyyy';
  public static readonly DATETIME_FMT = 'dd/MM/yyyy HH:mm';
  public static readonly MESSAGE_DELETE_SUCCESS = 'Delete successfully.';
  public static readonly MESSAGE_ADD_SUCCESS = 'Add successfully.';
  public static readonly MESSAGE_SERVICE_ERROR = 'Service error.';
  public static readonly MESSAGE_UPDATE_SUCCESS = 'Update successfully.';
  public static readonly SUCCESS = 'success';
  public static readonly INFO = 'info';
  public static readonly ERROR = 'danger';
  public static readonly WARNING = 'warning';
  public static readonly TOKEN = 'token';
  public static readonly ACTION = 'action';
  public static readonly USER_INFO = 'userinfo';
  public static readonly USER_LIST = 'loginusers';
  public static readonly CONFIG = 'config';
  public static readonly APPROVE_MODE = 'approve_mode';
  public static readonly WATCH_CONFIG = 'watch_config';
  public static readonly PRINT_TEMPLATES = 'print_temlates';
  public static readonly LOGIN_FAIL = 'Sai tên đăng nhập hoặc mật khẩu';
  public static readonly DELETE = 'Xóa';
  public static readonly CREATE = 'Thêm';
  public static readonly UPDATE = 'Sửa';
  public static readonly ADMIN_DASHBOARD = '/';
  public static readonly PORTAL_DASHBOARD = 'portal/dashboard';
  public static readonly CSC_DASHBOARD = 'csc/dashboard';
  public static readonly PHONG = 'phong';
  public static readonly SIGNUP_SUCCESS = 'Sign up successfully.';

  public static readonly DON_HANG = 'donhang';
  public static readonly INIT_DATA = 'initial_data';
  public static readonly LANGUAGE = 'language';

  public static readonly ORDER_STATUS_WAITING_VERIFY = 0;
  public static readonly ORDER_STATUS_VERIFIED = 10;
  public static readonly ORDER_STATUS_PAID = 15;
  public static readonly ORDER_STATUS_VEKO_CONFIRMED = 18;
  public static readonly ORDER_STATUS_READY4PICKING = 20;
  public static readonly ORDER_STATUS_PICKED = 30;
  public static readonly ORDER_STATUS_COMPLETED = 40;
  public static readonly ORDER_STATUS_CANCELLED = 50;
  public static readonly ORDER_STATUS_CANCELLED_BY_EMS = 60;

  public static readonly ORDER_CATEGORY_DOCUMENT = 91;
  public static readonly ORDER_CATEGORY_COMMERCIAL_SAMPLE = 32;
  public static readonly ORDER_CATEGORY_GIFT = 31;
  public static readonly ORDER_CATEGORY_SALES_OF_GOODS = 11;
  public static readonly ORDER_CATEGORY_OTHER = 991;

  public static readonly ORDER_SHIPPING_STATUS_CREATED = 1;
  public static readonly ORDER_SHIPPING_STATUS_WAITING = 2;
  public static readonly ORDER_SHIPPING_STATUS_PICKED = 3;
  public static readonly ORDER_SHIPPING_STATUS_SHIPPING = 4;
  public static readonly ORDER_SHIPPING_STATUS_EXPORTING = 5;
  public static readonly ORDER_SHIPPING_STATUS_NON_SUCCESS = 6;
  public static readonly ORDER_SHIPPING_STATUS_SUCCESS = 7;
  public static readonly ORDER_SHIPPING_STATUS_SHIPBACK = 8;
  public static readonly ORDER_SHIPPING_STATUS_CANCELLED = 9;

  public static readonly ORDER_PAYMENT_STATUS_UNPAID = 1;
  public static readonly ORDER_PAYMENT_STATUS_PAID = 10;
  public static readonly ORDER_PAYMENT_STATUS_GOT_ACTUAL_FEE = 20;
  public static readonly ORDER_PAYMENT_STATUS_REFUNDED = 30;
  public static readonly ORDER_PAYMENT_STATUS_SENT_SURCHARGE_REQUEST = 40;
  public static readonly ORDER_PAYMENT_STATUS_SURCHAGE_COMPLETE = 50;
  public static readonly ORDER_PAYMENT_STATUS_COMPLETED = 60

  public static readonly ADDITIONAL_CHARGE_ON_BASIC_CHARGE = 1;
  public static readonly ADDITIONAL_CHARGE_ON_DESTINATION = 2;
  public static readonly ADDITIONAL_CHARGE_ON_FAR_AREA = 3;

  public static readonly ADDITIONAL_CHARGE_ABSOLUTE = 1;
  public static readonly ADDITIONAL_CHARGE_RELATIVE = 2;
  public static readonly ADDITIONAL_CHARGE_WEIGHT_CHARGE = 3;

  public static readonly DISCOUNT_TYPE_ABSOLUTE = 1;
  public static readonly DISCOUNT_TYPE_RELATIVE = 2;
  public static readonly FIREBASE_TOKEN = 'fbToken';
  public static readonly GOOGLE_TOKEN = 'ggToken'

  public static readonly UNIT_PIECE = 1;
  public static readonly UNIT_PACKAGE = 2;
  public static readonly UNIT_SET = 3;
  public static readonly UNIT_CARTONS = 4;
  public static readonly UNIT_DOZEN = 5;
  public static readonly UNIT_LITERS = 6;
  public static readonly UNIT_METERS = 7;
  public static readonly UNIT_PAIRS = 8;
  public static readonly UNIT_SQUARE_METERS = 9;
  public static readonly SESSION_PAGE = 'page_session';

  public static readonly STUDY_FILTER = 'study_filter';

  public static readonly STORAGE_STUDENTS = 'students';
  public static readonly STORAGE_SELECTED_STUDENT = 'selected_student';
  public static readonly STORAGE_OTHER_USER = 'other_user';
  public static readonly USER_ID = 'userId';


  public static readonly USERTYPE_TEACHER = 3;
  public static readonly USERTYPE_STUDENT = 4;
  public static readonly USERTYPE_PARENT = 12;
  public static readonly USERTYPE_SCHOOL_ADMIN = 2;

  public static readonly RECEIVETYPE_CLASS = 1004;
  public static readonly RECEIVETYPE_DEPARTMENT = 1003;

  public static readonly NOTIFICATION_TYPE_SMS = 0;
  public static readonly NOTIFICATION_TYPE_MEDIA = 1;
  public static readonly NOTIFICATION_TYPE_SCORE = 2;
  public static readonly NOTIFICATION_TYPE_CONVERSATION = 3;
  public static readonly NOTIFICATION_TYPE_ABSENCE = 4;
  public static readonly NOTIFICATION_TYPE_REVIEW = 5;
  public static readonly NOTIFICATION_TYPE_POLLING = 6;
  public static readonly NOTIFICATION_TYPE_REQUEST_PERMISSION = 7;
  public static readonly NOTIFICATION_TYPE_HOMEWORK = 8;
  /*
  Message = 0,
  Announcement = 1,
  Score = 2,
  Feedback = 3,
  Attendent = 4,
  Review = 5,
  Polling = 6,
  RequestPermission = 7*/
}
